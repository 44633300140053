import React, { useState, useEffect } from "react"
import { AuthService, useAuth } from "gatsby-theme-auth0"
import StoryBuilder from "../components/StoryBuilder"
import FamilyTable from "../components/FamilyTable"
import ButtonCustom from "../components/ButtonCustom"
import { Tabs, Button, Alert } from "antd"
import Loading from "../components/Loading"
import { navigate } from "gatsby"
import styles from "../styles/modules/static-pages.module.scss"
import GiftCardImporter from "../components/GiftCardImporter"

const { TabPane } = Tabs

const TriggerRebuild = () => {
  const [hasBuilt, setHasBuilt] = useState(false)

  const triggerBuild = () => {
    fetch("https://api.netlify.com/build_hooks/674f2fa417b26b1f62b74557", {
      method: "POST",
    }).then(_ => setHasBuilt(true))
  }

  return (
    <ButtonCustom
      size={"large"}
      onClick={() => triggerBuild()}
      loading={hasBuilt}
    >
      Rebuild site
    </ButtonCustom>
  )
}

const Login = ({ message, login }) => (
  <section className={`wrapper--centered`} style={{ padding: "4em" }}>
    <h2 className={`x-large-body-copy ${styles.title}`}>Administration</h2>
    {message && (
      <Alert
        type="warning"
        message={message}
        style={{ marginBottom: "3rem" }}
      />
    )}
    <ButtonCustom size={"large"} hierarchy={"primary"} onClick={login}>
      Log in
    </ButtonCustom>
  </section>
)

const Admin = () => {
  const { isLoading, isLoggedIn, profile } = useAuth()
  const [editing, setEditing] = useState(null)
  const [activeKey, setActiveKey] = useState("1")

  useEffect(() => {
    if (editing) {
      setActiveKey("2")
    }
  }, [editing])

  if (isLoading) {
    return <Loading />
  }

  if (!isLoggedIn) {
    return <Login login={AuthService.login} />
  }

  if (isLoggedIn && !profile["https://nbshare.org/roles"].includes("admin")) {
    return (
      <Login
        login={AuthService.login}
        message={"User not entitled for admin panel. Contact engineering."}
      />
    )
  }

  return (
    <section className={`wrapper--centered`} style={{ padding: "4em" }}>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        type={"card"}
        onTabClick={key => setActiveKey(key)}
      >
        <TabPane tab="Case Overview" key="1">
          <FamilyTable setEditing={setEditing} />
        </TabPane>
        <TabPane tab="Case Creator" key="2">
          <StoryBuilder editingFamilyId={editing} />
        </TabPane>
        <TabPane tab="Gift Card Importer" key="3">
          <GiftCardImporter />
        </TabPane>
        <TabPane tab="Actions" key="4">
          <div style={{ margin: "3em" }}>
            <TriggerRebuild />
          </div>
          <div>
            <ButtonCustom
              size={"large"}
              hierarchy={"primary"}
              onClick={AuthService.logout}
            >
              Logout
            </ButtonCustom>
          </div>
        </TabPane>
      </Tabs>
    </section>
  )
}

export default Admin
